import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import api from '~/services/api';
import useSelector from '~/store/useSelector';
import Button from '~/styles/components/Button';
import Can from '~/components/Can';
import { TableComponent } from '~/components/TableV2';

const List: React.FC = () => {
  const { permissions } = useSelector(state => state.auth);

  const history = useHistory();

  return (
    <Container>
      <div className="d-flex justify-content-end">
        <Can checkPermission="banner_store">
          <Button type="button" onClick={() => history.push('/banners/create')}>
            Novo Banner
          </Button>
        </Can>
      </div>

      <TableComponent
        filters={[]}
        labels={[
          {
            key: 'order',
            title: 'Ordem',
          },
          {
            key: 'path',
            title: 'Imagem',
            formater: (url, banner) => (
              <img
                src={url || '/sem-imagem.png'}
                width={url ? '400' : '200'}
                alt={String(banner.position)}
              />
            ),

            ordenable: false,
          },
          {
            key: 'position',
            title: 'Posição',
            formater: value => {
              switch (value) {
                case 'main':
                  return <span className="info">Principal</span>;
                case 'slider':
                  return <span className="info">Slide</span>;
                case 'fixed':
                  return <span className="info">Fixo</span>;
                default:
                  return <span className="warning">Não cadastrado</span>;
              }
            },
          },

          {
            key: 'status',
            title: 'Status',
            formater: status =>
              status ? (
                <span className="ativo">Ativo</span>
              ) : (
                <span className="inativo">Inativo</span>
              ),
            ordenable: false,
          },
        ]}
        getData={async params => {
          const res = await api.get(`/banners`, {
            params,
          });
          return res;
        }}
        additionalActions={item => (
          <div className="d-flex align-items-center">
            {permissions.find(p => p.slug === 'banner_update') ? (
              <>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => history.push(`/banners/edit/${item.id}`)}
                >
                  Editar
                  <AiOutlineArrowRight />
                </button>
              </>
            ) : null}
          </div>
        )}
      />
    </Container>
  );
};

export default List;
