import React, { useState, useEffect } from 'react';

import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import Autocomplete from 'react-autocomplete';
import DatePicker, { registerLocale } from 'react-datepicker';
import api from '~/services/api';
import Input from '~/components/Input';
import InputFile from '~/components/InputFile';
import Button from '~/styles/components/Button';
import pt from 'date-fns/locale/pt-BR'; // eslint-disable-line
import { Container } from './styles';
import { Label } from '~/components/Input/styles';
import { states } from '~/utils/states';

registerLocale('pt-BR', pt);

interface IChange {
  name: string;
  value: string | string[];
}

interface IBanner {
  file: File | null;
  order: number;
  position: 'main' | 'slider' | 'fixed' | null;
  link: 'web' | 'product' | 'category' | 'brand' | 'campaign' | null;
  reference: string;
  status: boolean;
  start_date: Date | string;
  end_date: Date | string;
  device: 'site' | 'app';
  state: string | null;
}

interface IProduct {
  id: number;
  sku: string;
  name: string;
  price: number;
  special_price: number;
  status: boolean;
  featured: boolean;
  created_at: Date;
  updated_at: Date;
}
interface IBrand {
  id: number;
  name: string;
  image: string;
  created_at: string;
  updated_at: string;
}
interface ICategory {
  id: number;
  parent: string;
  category_id: number;
  categories: ICategory[];
  name: string;
  description: string;
  image: string;
}

interface ICampaign {
  id: number;
  name: string;
  productCampaign: IProduct[];
}

const Create: React.FC = () => {
  const [values, setValues] = useState<IBanner>({
    file: null,
    order: 0,
    position: null,
    link: null,
    reference: '',
    status: true,
    start_date: new Date(),
    end_date: new Date(),
    device: 'site',
    state: '',
  });
  const [autocompleteText, setAutocompleteText] = useState('');
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [products, setProducts] = useState<IProduct[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const history = useHistory();

  useEffect(() => {
    async function loadAll() {
      const { data: brandsData } = await api.get(
        `/brands?perpage=10000000&page=1`,
      );
      const { data: categoriesData } = await api.get(
        `/categories?perpage=10000000&page=1`,
      );

      setBrands(brandsData.data);
      setCategories(
        categoriesData.data.reduce(
          (categoryArr: ICategory[], category: ICategory) => {
            categoryArr.push(category);

            const subcategories = category?.categories;

            if (subcategories && subcategories.length > 0) {
              for (let i = 0; i < subcategories.length; i += 1) {
                const subcategory = subcategories[i];

                const subcategoryCopy = { ...subcategory };

                subcategoryCopy.name = `${category.name} > ${subcategory.name}`;

                categoryArr.push(subcategoryCopy);

                if (
                  subcategory?.categories &&
                  subcategory.categories.length > 0
                ) {
                  subcategory.categories.forEach(subsubcategory => {
                    const subsubcategoryCopy = { ...subsubcategory };

                    subsubcategoryCopy.name = `${category.name} > ${subcategory.name} > ${subsubcategory.name}`;

                    categoryArr.push(subsubcategoryCopy);

                    if (
                      subsubcategory?.categories &&
                      subsubcategory.categories.length > 0
                    ) {
                      subsubcategory.categories.forEach(subsubsubcategory => {
                        const subsubsubcategoryCopy = { ...subsubsubcategory };

                        subsubsubcategoryCopy.name = `${category.name} > ${subcategory.name} > ${subsubcategory.name} > ${subsubsubcategory.name}`;

                        categoryArr.push(subsubsubcategoryCopy);

                        if (
                          subsubsubcategory?.categories &&
                          subsubsubcategory.categories.length > 0
                        ) {
                          subsubsubcategory.categories.forEach(
                            subsubsubsubcategory => {
                              const subsubsubsubcategoryCopy = {
                                ...subsubsubsubcategory,
                              };

                              subsubsubsubcategoryCopy.name = `${category.name} > ${subcategory.name} > ${subsubcategory.name} > ${subsubsubcategory.name} > ${subsubsubsubcategory.name}`;

                              categoryArr.push(subsubsubsubcategoryCopy);
                            },
                          );
                        }
                      });
                    }
                  });
                }
              }
            }

            return categoryArr;
          },
          [],
        ),
      );
    }
    loadAll();
  }, []);

  useEffect(() => {
    async function loadProducts() {
      const { data: productsData } = await api.get(
        `/products?perpage=25&page=1&name=${autocompleteText}`,
      );

      setProducts(productsData.data);
    }

    loadProducts();
  }, [autocompleteText]);

  async function handleAdd() {
    setLoading(true);

    try {
      const {
        file,
        order,
        position,
        link,
        reference,
        status,
        start_date,
        end_date,
        device,
        state,
      } = values;

      const formData = new FormData();

      if (file) {
        formData.append('file', file);
      }

      const params = {
        order: String(order),
        position: position || '',
        link: link || '',
        reference,
        status: String(status),
        start_date,
        end_date,
        device,
        state,
      };

      const response = await api.post('/banners', formData, {
        params,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 203) {
        response.data.forEach(({ message }: any) => {
          toastr.error(message, '');
        });
      } else {
        history.push('/banners/list');
        toastr.success('Banner criado', '');
      }
    } catch (error) {
      toastr.error('Ocorreu um erro', '');
    }

    setLoading(false);
  }

  function handleChange(e: IChange) {
    setValues({
      ...values,
      [e.name]: String(e.value),
    });
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/banners/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Criar Banner</h3>
        </div>
        <div className="body_block">
          <div className="row mb-3">
            <div className="col-md-12">
              <InputFile
                label="Imagem"
                name="file"
                required
                onChange={e =>
                  setValues({
                    ...values,
                    file: e.value,
                  })
                }
                onError={() => console.log(1) /* eslint-disable-line */}
                onReset={() => console.log(1) /* eslint-disable-line */}
                disabled={loading}
                minHeight="200px"
              />
            </div>
            <div className="col-md-4">
              <Input
                label="Ordem"
                name="order"
                required
                type="number"
                onChange={handleChange}
                disabled={loading}
              />
            </div>
            <div className="col-md-4">
              <Input
                label="Posição"
                name="position"
                required
                select
                options={[
                  {
                    title: 'Principal',
                    value: 'main',
                  },
                  {
                    title: 'Slide',
                    value: 'slider',
                  },
                  {
                    title: 'Fixo',
                    value: 'fixed',
                  },
                ]}
                onChange={handleChange}
                disabled={loading}
              />
            </div>
            <div className="col-md-3 mb-2">
              <Input
                label="Destino"
                name="device"
                initialValue={values.device || ''}
                required
                select
                options={[
                  {
                    title: 'Site',
                    value: 'site',
                  },
                  {
                    title: 'App',
                    value: 'app',
                  },
                ]}
                onChange={handleChange}
                disabled={loading}
              />
            </div>
            <div className="col-md-4">
              <Input
                label="Link"
                name="link"
                required={false}
                select
                options={[
                  {
                    title: 'Web',
                    value: 'web',
                  },
                  {
                    title: 'Produto',
                    value: 'product',
                  },
                  {
                    title: 'Categoria',
                    value: 'category',
                  },
                  {
                    title: 'Marca',
                    value: 'brand',
                  },
                  {
                    title: 'Coleção',
                    value: 'campaign',
                  },
                ]}
                onChange={handleChange}
                disabled={loading}
              />
            </div>
            <div className="col-md-2">
              <Input
                label="Estado"
                name="state"
                onChange={handleChange}
                disabled={loading}
                select
                options={states.map(s => ({
                  title: s.desc,
                  value: s.uf,
                }))}
              />
            </div>
            <div className="col-md-10">
              {values.link === 'product' && (
                <>
                  <Label>Referência *</Label>
                  <Autocomplete
                    getItemValue={(item: any) => item.label}
                    items={products.map(p => ({
                      id: p.id,
                      label: `${p.sku} - ${p.name}`,
                    }))}
                    renderItem={(item: any, isHighlighted: boolean) => (
                      <div
                        style={{
                          width: '100%',
                          background: isHighlighted ? 'lightgray' : 'white',
                        }}
                      >
                        {item.label}
                      </div>
                    )}
                    value={autocompleteText}
                    onChange={(e: any) => {
                      setAutocompleteText(e.target.value);
                    }}
                    onSelect={(value: any, item: any) => {
                      setAutocompleteText(value);
                      setValues({ ...values, reference: String(item.id) });
                    }}
                    inputProps={{
                      style: {
                        width: '100%',
                        padding: '6px 10px',
                        background: '#eee',
                        border: 'none',
                        borderRadius: '4px',
                        fontSize: '12px',
                        color: '#2d3047',
                      },
                    }}
                    wrapperStyle={{ width: '100%' }}
                  />
                </>
              )}
              {values.link === 'brand' && (
                <Input
                  label="Referência (Marca)"
                  name="reference"
                  required
                  onChange={handleChange}
                  disabled={loading}
                  options={brands.map(b => {
                    return {
                      title: b.name,
                      value: String(b.id),
                    };
                  })}
                  select
                />
              )}
              {values.link === 'web' && (
                <Input
                  label="Referência (URL)"
                  name="reference"
                  required
                  onChange={handleChange}
                  disabled={loading}
                />
              )}
              {values.link === 'category' && (
                <Input
                  label="Referência (Categoria)"
                  name="reference"
                  required
                  onChange={handleChange}
                  disabled={loading}
                  options={categories.map(c => {
                    return {
                      title: c.name,
                      value: String(c.id),
                    };
                  })}
                  select
                />
              )}
              {values.link === 'campaign' && (
                <Input
                  label="Referência (Coleção)"
                  name="reference"
                  required
                  onChange={handleChange}
                  disabled={loading}
                  autoCompleteConfig={{
                    getOptions: async input => {
                      const { data: campaignData } = await api.get(
                        `/campaigns?perpage=10&page=1&name=${input}`,
                      );
                      return campaignData.data;
                    },
                    labelColumn: 'name',
                    valueColumn: 'id',
                  }}
                />
              )}

              <div className="col-md-2 align-items-center">
                <Label>Data de início *</Label>
                <DatePicker
                  className="datepicker-input"
                  selected={startDate}
                  onChange={date => {
                    setStartDate(date as Date);
                  }}
                  locale="pt-BR"
                  showTimeSelect
                  timeInputLabel="Hora:"
                  timeFormat="p"
                  timeIntervals={15}
                  dateFormat="Pp"
                />
              </div>
              <div className="col-md-2 align-items-center">
                <Label>Data de término *</Label>
                <DatePicker
                  className="datepicker-input"
                  selected={endDate}
                  onChange={date => {
                    setEndDate(date as Date);
                  }}
                  locale="pt-BR"
                  showTimeSelect
                  timeInputLabel="Hora:"
                  timeFormat="p"
                  timeIntervals={15}
                  dateFormat="Pp"
                />
              </div>
            </div>

            <div className="col-md-2">
              <Input
                label="Status"
                name="status"
                required
                select
                options={[
                  {
                    title: 'Ativo',
                    value: 'true',
                  },
                  {
                    title: 'Inativo',
                    value: 'false',
                  },
                ]}
                onChange={handleChange}
                disabled={loading}
                initialValue={values.status ? 'true' : 'false'}
              />
            </div>
          </div>
        </div>
        <div className="footer_block">
          <Button type="button" disabled={loading} onClick={handleAdd}>
            {loading ? 'Salvando...' : 'Salvar'}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Create;
