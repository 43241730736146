import React, { useEffect, useState } from 'react';

import { Switch, Route } from 'react-router-dom';

import Header from '~/components/Header';
import SideMenu from '~/components/SideMenu';
import Dashboard from '~/components/Dashboard';

import BrandsList from './Brands/List';
import BrandsCreate from './Brands/Create';
import BrandsEdit from './Brands/Edit';
import SellersCreate from './Sellers/Create';
import SellersList from './Sellers/List';
import SellersEdit from './Sellers/Edit';
import VtexCreate from './Vtex/Create';
import VtexList from './Vtex/List';
import VtexEdit from './Vtex/Edit';
import BannersCreate from './Banners/Create';
import BannersList from './Banners/List';
import BannersEdit from './Banners/Edit';
import CategoriesCreate from './Categories/Create';
import CategoriesList from './Categories/List';
import CategoriesEdit from './Categories/Edit';
import PercentCategoriesList from './PercentCategories/List';
import PercentCategoriesEdit from './PercentCategories/Edit';
import CampaignsCreate from './Campaigns/Create';
import CampaignsList from './Campaigns/List';
import CampaignsEdit from './Campaigns/Edit';
import CouponsCreate from './Coupons/Create';
import CouponsList from './Coupons/List';
import CouponsEdit from './Coupons/Edit';
import RolesCreate from './Roles/Create';
import RolesList from './Roles/List';
import RolesEdit from './Roles/Edit';
import PermissionsCreate from './Permissions/Create';
import PermissionsList from './Permissions/List';
import PermissionsEdit from './Permissions/Edit';
import StoresCreate from './Stores/Create';
import StoresList from './Stores/List';
import StoresEdit from './Stores/Edit';
import MarketplacesList from './Marketplaces/List';
import MarketplacesDetail from './Marketplaces/Detail';
import UsersCreate from './Users/Create';
import UsersList from './Users/List';
import UsersEdit from './Users/Edit';
import PagesCreate from './Pages/Create';
import PagesList from './Pages/List';
import PagesEdit from './Pages/Edit';
import ProductsCreate from './Products/Create';
import ProductsList from './Products/List';
import ProductsEdit from './Products/Edit';
import CustomersList from './Customers/List';
import CustomersView from './Customers/View';
import OrdersList from './Orders/List';
import OrdersView from './Orders/View';
import OrdersAbandonedsList from './OrdersAbandoned/List';
import OrdersAbandonedsView from './OrdersAbandoned/View';
import Reports from './Reports/index';
import Settings from './Settings/index';
import EcommerceMain from './EcommerceMain';

import { Container, Operative } from './styles';
import { ConfigMarketplace } from './ConfigMarketplace';
import DeleteRequestsList from './DeleteRequests/List';
import ImperioCard from './ImperioCard';
import ImperioCardCustomer from './ImperioCard/customer';
import useSelector from '~/store/useSelector';
import PaymentLinks from './PaymentLinks';
import PaymentLinksRegister from './PaymentLinks/register';
import api from '~/services/api';
import AppTerms from './AppTerms';
import AppTermsRegister from './AppTerms/register';
import Reviews from './Reviews';
import ZipcodeTable from './ZipcodeFastShipping';

const Main: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { permissions, token } = useSelector(state => state.auth);

  useEffect(() => {
    if (token) {
      api
        .get('/user/me', {
          headers: {
            apptoken: token,
          },
        })
        .then(() => setLoading(false));
    }
  }, [token]);

  return (
    <>
      <Header />
      <Container>
        {!loading ? <SideMenu /> : null}
        <Operative>
          {loading ? (
            <div className="p-4">Verificando dados...</div>
          ) : (
            <Switch>
              {permissions.findIndex(p => p.slug === 'ver_dashboard') > -1 ? (
                <Route exact path="/" component={Dashboard} />
              ) : null}

              <Route
                exact
                path="/ecommerce/main-page"
                component={EcommerceMain}
              />
              <Route exact path="/brands/list" component={BrandsList} />
              <Route exact path="/brands/create" component={BrandsCreate} />
              <Route exact path="/brands/edit/:id" component={BrandsEdit} />
              <Route exact path="/coupons/list" component={CouponsList} />
              <Route exact path="/coupons/create" component={CouponsCreate} />
              <Route exact path="/coupons/edit/:id" component={CouponsEdit} />
              <Route
                exact
                path="/marketplaces/list"
                component={MarketplacesList}
              />
              <Route
                exact
                path="/marketplaces/detail/:id"
                component={MarketplacesDetail}
              />
              <Route exact path="/categories/list" component={CategoriesList} />
              <Route
                exact
                path="/categories/create"
                component={CategoriesCreate}
              />
              <Route
                exact
                path="/categories/edit/:id"
                component={CategoriesEdit}
              />
              <Route
                exact
                path="/percent-categories/list"
                component={PercentCategoriesList}
              />
              <Route
                exact
                path="/percent-categories/edit/:id"
                component={PercentCategoriesEdit}
              />
              <Route exact path="/roles/create" component={RolesCreate} />
              <Route exact path="/roles/list" component={RolesList} />
              <Route exact path="/roles/edit/:id" component={RolesEdit} />
              <Route
                exact
                path="/permissions/create"
                component={PermissionsCreate}
              />
              <Route
                exact
                path="/permissions/list"
                component={PermissionsList}
              />
              <Route
                exact
                path="/permissions/edit/:id"
                component={PermissionsEdit}
              />
              <Route exact path="/banners/list" component={BannersList} />
              <Route exact path="/banners/create" component={BannersCreate} />
              <Route exact path="/banners/edit/:id" component={BannersEdit} />
              <Route exact path="/campaigns/list" component={CampaignsList} />
              <Route
                exact
                path="/campaigns/create"
                component={CampaignsCreate}
              />
              <Route
                exact
                path="/campaigns/edit/:id"
                component={CampaignsEdit}
              />
              <Route exact path="/users/list" component={UsersList} />
              <Route exact path="/users/create" component={UsersCreate} />
              <Route exact path="/users/edit/:id" component={UsersEdit} />
              <Route exact path="/sellers/list" component={SellersList} />
              <Route exact path="/sellers/create" component={SellersCreate} />
              <Route exact path="/sellers/edit/:id" component={SellersEdit} />
              <Route exact path="/vtex/list" component={VtexList} />
              <Route exact path="/vtex/create" component={VtexCreate} />
              <Route exact path="/vtex/edit/:id" component={VtexEdit} />
              <Route exact path="/stores/list" component={StoresList} />
              <Route exact path="/stores/create" component={StoresCreate} />
              <Route exact path="/stores/edit/:id" component={StoresEdit} />
              <Route exact path="/pages/list" component={PagesList} />
              <Route exact path="/pages/create" component={PagesCreate} />
              <Route exact path="/pages/edit/:id" component={PagesEdit} />
              <Route exact path="/products/list" component={ProductsList} />
              <Route exact path="/products/create" component={ProductsCreate} />
              <Route exact path="/products/edit/:id" component={ProductsEdit} />
              <Route exact path="/customers/list" component={CustomersList} />
              <Route
                exact
                path="/customers/view/:id"
                component={CustomersView}
              />
              <Route
                exact
                path="/delete-requests"
                component={DeleteRequestsList}
              />
              <Route exact path="/orders/view/:id" component={OrdersView} />
              <Route exact path="/orders/list" component={OrdersList} />
              <Route
                exact
                path="/orders/abandoned/view/:id"
                component={OrdersAbandonedsView}
              />
              <Route
                exact
                path="/orders/abandoned/list"
                component={OrdersAbandonedsList}
              />
              <Route exact path="/reports" component={Reports} />
              <Route
                exact
                path="/config-marketplace"
                component={ConfigMarketplace}
              />

              <Route exact path="/settings" component={Settings} />
              <Route exact path="/imperio-card" component={ImperioCard} />
              <Route
                exact
                path="/imperio-card/:id"
                component={ImperioCardCustomer}
              />
              <Route exact path="/payment-links" component={PaymentLinks} />
              <Route
                exact
                path="/payment-links/register"
                component={PaymentLinksRegister}
              />
              <Route
                exact
                path="/payment-links/register/:id"
                component={PaymentLinksRegister}
              />
              <Route exact path="/app-terms" component={AppTerms} />
              <Route
                exact
                path="/app-terms/register"
                component={AppTermsRegister}
              />
              <Route
                exact
                path="/app-terms/register/:id"
                component={AppTermsRegister}
              />
              <Route exact path="/reviews" component={Reviews} />
              <Route
                exact
                path="/zipcode-fast-shipping"
                component={ZipcodeTable}
              />
            </Switch>
          )}
        </Operative>
      </Container>
    </>
  );
};

export default Main;
