import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import Autocomplete from 'react-autocomplete';
import pt from 'date-fns/locale/pt-BR'; // eslint-disable-line
import DatePicker, { registerLocale } from 'react-datepicker';
import api from '~/services/api';
import Input from '~/components/Input';
import InputFile from '~/components/InputFile';
import Button from '~/styles/components/Button';
import { Label } from '~/components/Input/styles';
import { Container } from './styles'; //eslint-disable-line
import { states } from '~/utils/states';

registerLocale('pt-BR', pt);

interface IChange {
  name: string;
  value: string | string[];
}

interface IBanner {
  file: File | null;
  preview: string;
  order: number;
  position: 'main' | 'slider' | 'fixed' | null;
  link: 'web' | 'product' | 'category' | 'brand' | 'campaign' | null;
  reference: string;
  status: boolean;
  device: 'site' | 'app';
  state: string | null;
}

interface IProduct {
  id: number;
  sku: string;
  name: string;
  price: number;
  special_price: number;
  status: boolean;
  featured: boolean;
  created_at: Date;
  updated_at: Date;
}
interface IBrand {
  id: number;
  name: string;
  image: string;
  created_at: string;
  updated_at: string;
}
interface ICategory {
  id: number;
  parent?: string;
  category_id: number | null;
  categories?: ICategory[];
  name: string;
  description: string | null;
  image: string | null;
}
interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}

const Edit: React.FC<IProps> = ({ match }) => {
  const [values, setValues] = useState<IBanner>({
    file: null,
    preview: '',
    order: 0,
    position: null,
    link: null,
    reference: '',
    status: false,
    device: 'site',
    state: '',
  });
  const [autocompleteText, setAutocompleteText] = useState('');
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmImageDelete, setConfirmImageDelete] = useState(false);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);

  const history = useHistory();

  useEffect(() => {
    async function loadStore() {
      try {
        const { data } = await api.get(`/banners/${match.params.id}`);
        setValues({
          ...data,
          order: data.order,
          file: null,
          preview: data.path,
          status: data.status === 1 ? 'true' : 'false',
          state: data.state || '',
        });

        setStartDate(new Date(data.start_date));
        setEndDate(new Date(data.end_date));

        if (data.link === 'product') {
          const { data: product } = await api.get(
            `/products/${data.reference}`,
          );
          setAutocompleteText(`${product.sku} - ${product.name}`);
        }

        setLoading(false);
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadStore();
  }, [match.params.id]); // eslint-disable-line

  useEffect(() => {
    async function loadAll() {
      const { data: brandsData } = await api.get(
        `/brands?perpage=10000000&page=1`,
      );
      const { data: categoriesData } = await api.get(
        `/categories?perpage=10000000&page=1`,
      );

      setBrands(brandsData.data);
      setCategories(
        categoriesData.data.reduce(
          (categoryArr: ICategory[], category: ICategory) => {
            categoryArr.push(category);

            const subcategories = category?.categories;

            if (subcategories && subcategories.length > 0) {
              for (let i = 0; i < subcategories.length; i += 1) {
                const subcategory = subcategories[i];

                const subcategoryCopy = { ...subcategory };

                subcategoryCopy.name = `${category.name} > ${subcategory.name}`;

                categoryArr.push(subcategoryCopy);

                if (
                  subcategory?.categories &&
                  subcategory.categories.length > 0
                ) {
                  subcategory.categories.forEach(subsubcategory => {
                    const subsubcategoryCopy = { ...subsubcategory };

                    subsubcategoryCopy.name = `${category.name} > ${subcategory.name} > ${subsubcategory.name}`;

                    categoryArr.push(subsubcategoryCopy);

                    if (
                      subsubcategory?.categories &&
                      subsubcategory.categories.length > 0
                    ) {
                      subsubcategory.categories.forEach(subsubsubcategory => {
                        const subsubsubcategoryCopy = { ...subsubsubcategory };

                        subsubsubcategoryCopy.name = `${category.name} > ${subcategory.name} > ${subsubcategory.name} > ${subsubsubcategory.name}`;

                        categoryArr.push(subsubsubcategoryCopy);

                        if (
                          subsubsubcategory?.categories &&
                          subsubsubcategory.categories.length > 0
                        ) {
                          subsubsubcategory.categories.forEach(
                            subsubsubsubcategory => {
                              const subsubsubsubcategoryCopy = {
                                ...subsubsubsubcategory,
                              };

                              subsubsubsubcategoryCopy.name = `${category.name} > ${subcategory.name} > ${subsubcategory.name} > ${subsubsubcategory.name} > ${subsubsubsubcategory.name}`;

                              categoryArr.push(subsubsubsubcategoryCopy);
                            },
                          );
                        }
                      });
                    }
                  });
                }
              }
            }

            return categoryArr;
          },
          [],
        ),
      );
    }
    loadAll();
  }, []);

  useEffect(() => {
    async function loadProducts() {
      const { data: productsData } = await api.get(
        `/products?perpage=25&page=1&name=${autocompleteText}`,
      );

      setProducts(productsData.data);
    }

    loadProducts();
  }, [autocompleteText]);

  async function handleEdit() {
    setLoading(true);

    const {
      file,
      order,
      position,
      link,
      reference,
      status,
      device,
      state,
    } = values;

    const { id } = match.params;

    const url = file ? `/banners/image/${id}` : `/banners/${id}`;
    const params = {
      order: String(order),
      position: position || '',
      link: link || '',
      reference,
      start_date: startDate,
      end_date: endDate,
      status: String(status) === 'true' ? 1 : 0,
      ...(file ? {} : { device, state: state || '' }),
    };
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }

    try {
      const response = await api.put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params,
      });

      if (response.status === 203) {
        response.data.forEach((i: any) => {
          toastr.error(i.message, '');
        });
      } else {
        history.push('/banners/list');
        toastr.success('Banner atualizado', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }

    setLoading(false);
  }

  async function handleDelete() {
    setLoading(true);
    try {
      const response = await api.delete(`/banners/${match.params.id}`);
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/banners/list');
        toastr.success('Banner deletado', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  async function handleImageDelete() {
    setLoading(true);
    try {
      const response = await api.delete(`/banners/image/${match.params.id}`);
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        setValues({ ...values, preview: '' });
        setConfirmImageDelete(false);
        toastr.success('Imagem excluida', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  function handleChange(e: IChange) {
    setValues({
      ...values,
      [e.name]: String(e.value),
    });
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/banners/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Editar Banner</h3>
        </div>
        {!loading && (
          <>
            <div className="body_block">
              <div className="row mb-3">
                <div className="col-md-12 mb-2">
                  <InputFile
                    label="Imagem"
                    name="file"
                    required
                    initialValue={values.preview}
                    onChange={e =>
                      setValues({
                        ...values,
                        file: e.value,
                      })
                    }
                    onError={() => console.log(1) /* eslint-disable-line */}
                    onReset={() => console.log(1) /* eslint-disable-line */}
                    disabled={loading}
                    minHeight="200px"
                  />
                </div>
                {values.preview && !confirmImageDelete && (
                  <div className="footer_block d-flex justify-content-end">
                    <Button
                      type="button"
                      color="danger"
                      className="ml-2"
                      disabled={loading}
                      onClick={() => setConfirmImageDelete(true)}
                    >
                      {loading ? '...' : 'Excluir Imagem'}
                    </Button>
                  </div>
                )}
                {values.preview && confirmImageDelete && (
                  <div className="footer_block d-flex justify-content-end">
                    <div>
                      <h3 style={{ textAlign: 'right' }}>
                        Você tem certeza que deseja excluir esta imagem?
                      </h3>
                      <div className="d-flex mt-2 justify-content-end">
                        <Button
                          type="button"
                          color="danger"
                          disabled={loading}
                          onClick={handleImageDelete}
                        >
                          {loading ? 'Excluindo...' : 'Excluir'}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-2 mb-2">
                  <Input
                    label="Status"
                    name="status"
                    required
                    select
                    options={[
                      {
                        title: 'Ativo',
                        value: 'true',
                      },
                      {
                        title: 'Inativo',
                        value: 'false',
                      },
                    ]}
                    onChange={handleChange}
                    disabled={loading}
                    initialValue={String(values.status)}
                    initialChanges
                  />
                </div>
                <div className="col-md-3 mb-2">
                  <Input
                    label="Ordem"
                    name="order"
                    type="number"
                    initialValue={String(values.order)}
                    required
                    onChange={handleChange}
                    disabled={loading}
                    initialChanges
                  />
                </div>
                <div className="col-md-3 mb-2">
                  <Input
                    label="Posição"
                    name="position"
                    initialValue={values.position || ''}
                    required
                    select
                    options={[
                      {
                        title: 'Principal',
                        value: 'main',
                      },
                      {
                        title: 'Slide',
                        value: 'slider',
                      },
                      {
                        title: 'Fixo',
                        value: 'fixed',
                      },
                    ]}
                    onChange={handleChange}
                    disabled={loading}
                    initialChanges
                  />
                </div>
                <div className="col-md-3 mb-2">
                  <Input
                    label="Destino"
                    name="device"
                    initialValue={values.device || ''}
                    required
                    select
                    options={[
                      {
                        title: 'Site',
                        value: 'site',
                      },
                      {
                        title: 'App',
                        value: 'app',
                      },
                    ]}
                    onChange={handleChange}
                    disabled={loading}
                    initialChanges
                  />
                </div>
                <div className="col-md-4 mb-2">
                  <Input
                    label="Link"
                    name="link"
                    initialValue={values.link || ''}
                    required={false}
                    select
                    options={[
                      {
                        title: 'Web',
                        value: 'web',
                      },
                      {
                        title: 'Produto',
                        value: 'product',
                      },
                      {
                        title: 'Categoria',
                        value: 'category',
                      },
                      {
                        title: 'Marca',
                        value: 'brand',
                      },
                      {
                        title: 'Coleção',
                        value: 'campaign',
                      },
                    ]}
                    onChange={handleChange}
                    disabled={loading}
                    initialChanges
                  />
                </div>
                <div className="col-md-2">
                  <Input
                    label="Estado"
                    name="state"
                    initialChanges
                    initialValue={values.state || ''}
                    onChange={handleChange}
                    disabled={loading}
                    select
                    options={states.map(s => ({
                      title: s.desc,
                      value: s.uf,
                    }))}
                  />
                </div>
                <div className="col-md-12 mb-2">
                  {values.link === 'product' && (
                    <>
                      <Label>Referência *</Label>
                      <Autocomplete
                        getItemValue={(item: any) => item.label}
                        items={products.map(p => ({
                          id: p.id,
                          label: `${p.sku} - ${p.name}`,
                        }))}
                        renderItem={(item: any, isHighlighted: boolean) => (
                          <div
                            style={{
                              width: '100%',
                              background: isHighlighted ? 'lightgray' : 'white',
                            }}
                          >
                            {item.label}
                          </div>
                        )}
                        value={autocompleteText}
                        onChange={(e: any) => {
                          setAutocompleteText(e.target.value);
                        }}
                        onSelect={(value: any, item: any) => {
                          setAutocompleteText(value);
                          setValues({ ...values, reference: String(item.id) });
                        }}
                        inputProps={{
                          style: {
                            width: '100%',
                            padding: '6px 10px',
                            background: '#eee',
                            border: 'none',
                            borderRadius: '4px',
                            fontSize: '12px',
                            color: '#2d3047',
                          },
                        }}
                        wrapperStyle={{ width: '100%' }}
                      />
                    </>
                  )}
                  {values.link === 'brand' && (
                    <Input
                      label="Referência (Marca)"
                      name="reference"
                      initialValue={values.reference}
                      required
                      onChange={handleChange}
                      disabled={loading}
                      options={brands.map(b => {
                        return {
                          title: b.name,
                          value: String(b.id),
                        };
                      })}
                      select
                      initialChanges
                    />
                  )}
                  {values.link === 'web' && (
                    <Input
                      label="Referência (URL)"
                      name="reference"
                      initialValue={values.reference}
                      required
                      onChange={handleChange}
                      disabled={loading}
                      initialChanges
                    />
                  )}
                  {values.link === 'category' && (
                    <Input
                      label="Referência (Categoria)"
                      name="reference"
                      initialValue={values.reference}
                      required
                      onChange={handleChange}
                      disabled={loading}
                      select
                      options={categories.map(c => {
                        return {
                          title: c.name,
                          value: String(c.id),
                        };
                      })}
                      initialChanges
                    />
                  )}
                  {values.link === 'campaign' && (
                    <Input
                      label="Referência (Coleção)"
                      name="reference"
                      required
                      initialValue={values.reference}
                      onChange={handleChange}
                      disabled={loading}
                      autoCompleteConfig={{
                        getOptions: async input => {
                          const { data: campaignData } = await api.get(
                            `/campaigns?perpage=10&page=1&name=${input}`,
                          );
                          return campaignData.data;
                        },
                        labelColumn: 'name',
                        valueColumn: 'id',
                      }}
                    />
                  )}
                  <div className="col-md-2 align-items-center">
                    <Label>Data de início *</Label>
                    <DatePicker
                      className="datepicker-input"
                      selected={startDate}
                      onChange={date => {
                        setStartDate(date as Date);
                      }}
                      locale="pt-BR"
                      showTimeSelect
                      timeInputLabel="Hora:"
                      timeFormat="p"
                      timeIntervals={15}
                      dateFormat="Pp"
                    />
                  </div>
                  <div className="col-md-2 align-items-center">
                    <Label>Data de término *</Label>
                    <DatePicker
                      className="datepicker-input"
                      selected={endDate}
                      onChange={date => {
                        setEndDate(date as Date);
                      }}
                      locale="pt-BR"
                      showTimeSelect
                      timeInputLabel="Hora:"
                      timeFormat="p"
                      timeIntervals={15}
                      dateFormat="Pp"
                    />
                  </div>
                </div>
              </div>
            </div>
            {!confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <Button type="button" disabled={loading} onClick={handleEdit}>
                  {loading ? 'Salvando...' : 'Salvar'}
                </Button>
                <Button
                  type="button"
                  color="danger"
                  className="ml-2"
                  disabled={loading}
                  onClick={() => setConfirmDelete(true)}
                >
                  {loading ? '...' : 'Excluir Banner'}
                </Button>
              </div>
            )}
            {confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <div>
                  <h3 style={{ textAlign: 'right' }}>
                    Você tem certeza que deseja excluir este banner?
                  </h3>
                  <div>
                    <Button
                      type="button"
                      color="danger"
                      disabled={loading}
                      onClick={handleDelete}
                    >
                      {loading ? 'Excluindo...' : 'Excluir'}
                    </Button>
                    <Button
                      type="button"
                      color="gray"
                      className="ml-2"
                      disabled={loading}
                      onClick={() => setConfirmDelete(false)}
                    >
                      {loading ? '...' : 'Cancelar'}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default Edit;
